import React, { useEffect, useState, useRef } from "react";
import BasicHeader from "../../Header/BasicHeader";
import { useParams } from "react-router-dom";
import "./Template15.css";
import { GETCOLLECTION, SENDMAIL } from "../../../server";

import Input from "../../Input/Input";
import ContactEmailTemplate from "../../PopUps/ContactPopup/ContactEmailTemplate";
import Phone from "../../../assets/phone.png";
import Location from "../../../assets/address.png";
import Email from "../../../assets/Mail2.png";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

const Template15 = ({ Data, screenWidth }) => {
  const recaptchaRef = useRef(); // Reference to reCAPTCHA
  const [isHuman, setIsHuman] = useState(false); // State to track reCAPTCHA

  const { NAME } = useParams();

  const [jobs, setJobs] = useState([]);
  const [activeJob, setActiveJob] = useState(null);

  useEffect(() => {
    const jobOptions = Data.Career.jobs.map((job) => {
      return { value: job.id, label: job.Designation };
    });
    setJobs(jobOptions);

    const selectedJob = Data.Career.jobs.find(
      (job) => job.Designation === NAME
    );
    setActiveJob(selectedJob);
  }, [Data, NAME]);

  const [formValues, setFormValues] = useState({
    Fname: "",
    Number: "",
    Email: "",
    Job: activeJob ? activeJob.id : "",
    Subject: "",
    Message: "",
  });

  useEffect(() => {
    if (activeJob) {
      setFormValues((prevValues) => ({
        ...prevValues,
        Job: activeJob.id,
      }));
    }
  }, [activeJob]);

  const [email, setEmail] = useState("");
  const [webName, setWebName] = useState("");
  useEffect(() => {
    const fetchEmail = async () => {
      const res = await GETCOLLECTION("customization");
      setEmail(res[2].Email);
      setWebName(res[2].WebsiteName);
    };
    fetchEmail();
  }, []);

  const onReCAPTCHAChange = (token) => {
    if (token) {
      setIsHuman(true); // User passed the reCAPTCHA
    } else {
      setIsHuman(false); // User failed or didn't complete reCAPTCHA
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const token = recaptchaRef.current.getValue(); // Get reCAPTCHA token
    if (!token || !isHuman) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    await SENDMAIL(
      ContactEmailTemplate,
      email,
      webName,
      "Contact Request Email",
      formValues,
      `Contact Request : ${formValues.Subject}`
    );
  };

  const handleFormInput = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => {
      return { ...prevValues, [name]: value };
    });
  };

  const handleJobChange = (selectedOption) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      Job: selectedOption.label,
    }));
  };

  return (
    <>
      <BasicHeader
        HeaderTitle={NAME ? `Apply for ${NAME}` : Data.Application.HeaderTitle}
        BottomTitle={Data.Application.BottomTitle}
        screenWidth={screenWidth}
      />
      <div className="TemplateGeneral Template15">
        <p className="description">{Data.Application.PageTitle}</p>
        <form className="ContactForm">
          <Input
            label="Name:"
            type="text"
            required={true}
            id="Fname"
            name="Fname"
            value={formValues.Fname}
            onChangeFunction={handleFormInput}
          />
          <div className="row-wrapper">
            <Input
              label="Email:"
              type="email"
              required={true}
              id="Email"
              name="Email"
              value={formValues.Email}
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Phone Number:"
              type="number"
              required={true}
              id="Number"
              name="Number"
              value={formValues.Number}
              onChangeFunction={handleFormInput}
            />
          </div>

          <Input
            label="Select Current Position:"
            type="text"
            required={true}
            id="Subject"
            name="Subject"
            value={formValues.Subject}
            onChangeFunction={handleFormInput}
          />
          <Select
            className="Position-Dropdown"
            placeholder="Select Position:"
            options={jobs}
            defaultValue={jobs.find((job) => job.label === NAME)}
            value={jobs.find((job) => job.value === formValues.Job)}
            onChange={handleJobChange}
          />
          <Input
            textarea={true}
            label="Let us know why you want to join us:"
            type="textarea"
            required={true}
            id="Message"
            name="Message"
            value={formValues.Message}
            onChangeFunction={handleFormInput}
          />
          <ReCAPTCHA
            sitekey="6LeI5P0iAAAAAEc3s5NTuyTgQIxUhIjIDfwRKt2M"
            onChange={onReCAPTCHAChange}
            ref={recaptchaRef}
            theme="dark"
            className="CAPTCHA"
          />
          <button onClick={sendEmail} className="Button">
            Send
          </button>
        </form>
        <div className="Contact-Info">
          <div className="Info-Box">
            <img
              src={
                Data.Contact.LocationIcon ? Data.Contact.LocationIcon : Location
              }
            />
            <div className="addressWrapper">
              <div className="address">
                <h5>{Data.Contact.firstAddressText}</h5>
                <p>{Data.Contact.firstAddress}</p>
              </div>
              <div className="address">
                <h5>{Data.Contact.secondAddressText}</h5>
                <p>{Data.Contact.secondAddress}</p>
              </div>
            </div>
          </div>
          <div className="Info-Box">
            <img
              src={Data.Contact.EmailIcon ? Data.Contact.EmailIcon : Email}
            />
            <p>{Data.Contact.Email}</p>
          </div>
          <div className="Info-Box">
            <img
              src={Data.Contact.PhoneIcon ? Data.Contact.PhoneIcon : Phone}
            />
            <p>{Data.Contact.Phone}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template15;
