import React, { useEffect, useState, useRef } from "react";
import "./Section6.css";
import Input from "../../Input/Input";
import { GETCOLLECTION, SENDMAIL } from "../../../server";
import ContactEmailTemplate from "../../PopUps/ContactPopup/ContactEmailTemplate";
import ReCAPTCHA from "react-google-recaptcha";

const Section6 = (props) => {
  const recaptchaRef = useRef(); // Reference to reCAPTCHA
  const [isHuman, setIsHuman] = useState(false); // State to track reCAPTCHA

  const [email, setEmail] = useState("");
  const [webName, setWebName] = useState("");
  const [formValues, setFormValues] = useState({
    Fname: "",
    Number: "",
    Email: "",
    Subject: "",
    Message: "",
  });
  useEffect(() => {
    const FetchEmail = async () => {
      const res = await GETCOLLECTION("customization");
      setEmail(res[2].Email);
      setWebName(res[2].WebsiteName);
    };
    FetchEmail();
  }, []);
  const onReCAPTCHAChange = (token) => {
    if (token) {
      setIsHuman(true); // User passed the reCAPTCHA
    } else {
      setIsHuman(false); // User failed or didn't complete reCAPTCHA
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const token = recaptchaRef.current.getValue(); // Get reCAPTCHA token
    if (!token || !isHuman) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    await SENDMAIL(
      ContactEmailTemplate,
      email,
      webName,
      "Contact Request Email",
      formValues,
      `Contact Request : ${formValues.Subject}`
    );
    setFormValues({
      Fname: "",
      Number: "",
      Email: "",
      Subject: "",
      Message: "",
    });
  };
  const handleFormInput = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  };
  return (
    <section
      className="Section4Duplicated"
      style={{
        backgroundColor: props.ServerData.BGColor && props.ServerData.BGColor,
      }}
    >
      <div className="Left">
        {props.ServerData.Tag && (
          <div
            className="Tag"
            style={{
              backgroundColor:
                getDataFromLocalStorage("TagBG") &&
                getDataFromLocalStorage("TagBG"),
            }}
          >
            <span>{props.ServerData.Tag}</span>
          </div>
        )}
        {props.ServerData.Title && <h2>{props.ServerData.Title}</h2>}
        <div className="form-wrapper">
          {props.screenWidth > 1000 && props.ServerData.thumbnail && (
            <div className="Right PhotoEffect">
              <img src={props.ServerData.thumbnail} />
            </div>
          )}
          <form className="ContactForm">
            <Input
              label="Name:"
              type="text"
              required={true}
              id="Fname"
              name="Fname"
              value={formValues.Fname}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Email:"
              type="email"
              required={true}
              id="Email"
              name="Email"
              value={formValues.Email}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Contact Number:"
              type="number"
              required={true}
              id="Number"
              name="Number"
              value={formValues.Number}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Subject:"
              type="text"
              required={true}
              id="Subject"
              name="Subject"
              value={formValues.Subject}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              textarea={true}
              label="Message:"
              type="textarea"
              required={true}
              id="Message"
              name="Message"
              value={formValues.Message}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />
            <ReCAPTCHA
              sitekey="6LeI5P0iAAAAAEc3s5NTuyTgQIxUhIjIDfwRKt2M"
              onChange={onReCAPTCHAChange}
              ref={recaptchaRef}
              theme="dark"
              className="CAPTCHA"
            />
            <button onClick={sendEmail} className="Button">
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Section6;
