import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CookiePopup.css";
const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(true);

  const handleAccept = () => {
    setShowPopup(false);
    localStorage.setItem("Cookies", true);
  };
  const handleReject = () => {
    setShowPopup(false);
    localStorage.setItem("Cookies", false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className="overlayCookie">
      <div className="cookie-popup">
        <h4>This site uses cookies to store information on your computer.</h4>
        <p>
          This website uses cookies to ensure you get the best experience on our
          website. Some of these cookies are essential for the site to function,
          whilst others are useful to help us to analyse our traffic and collect
          Statistics on how and when the site is being used and how it can be
          improved. We also share information about your use of our site with
          our analytics partners.
        </p>
        <p>
          By clicking '"Accept All," you consent to the storing of cookies on
          your device to enhance site navigation, analyse site usage, and assist
          in measuring the success of our marketing efforts.
        </p>
        <p style={{ textAlign: "left", width: "100%" }}>
          To learn more about how we process your personal data, read our
          <Link
            style={{ textDecoration: "none" }}
            to="/Privacy"
            className="Link"
          >
            Privacy Policy
          </Link>
        </p>
        <div className="button-wrapper">
          <button className="Button" onClick={handleAccept}>
            Accept All
          </button>
          <button className="Button Empty" onClick={handleReject}>
            Reject All Cookies
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
