import React, { useEffect, useState, useRef } from "react";
import Phone from "../../../assets/phone.png";
import Location from "../../../assets/address.png";
import Email from "../../../assets/Mail2.png";
import { GETCOLLECTION, SENDMAIL } from "../../../server";
import ContactEmailTemplate from "../../PopUps/ContactPopup/ContactEmailTemplate";
import ReCAPTCHA from "react-google-recaptcha";

import Input from "../../Input/Input";
import Map from "../../Map";
import "./Template14.css";
const Template14 = ({ Data }) => {
  const recaptchaRef = useRef(); // Reference to reCAPTCHA
  const [isHuman, setIsHuman] = useState(false); // State to track reCAPTCHA

  const [formValues, setFormValues] = useState({
    Fname: "",
    Number: "",
    Email: "",
    Subject: "",
    Message: "",
  });
  const [email, setEmail] = useState("");
  const [webName, setWebName] = useState("");
  useEffect(() => {
    const FetchEmail = async () => {
      const res = await GETCOLLECTION("customization");
      setEmail(res[2].Email);
      setWebName(res[2].WebsiteName);
    };
    FetchEmail();
  }, []);
  const onReCAPTCHAChange = (token) => {
    if (token) {
      setIsHuman(true); // User passed the reCAPTCHA
    } else {
      setIsHuman(false); // User failed or didn't complete reCAPTCHA
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const token = recaptchaRef.current.getValue(); // Get reCAPTCHA token
    if (!token || !isHuman) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    await SENDMAIL(
      ContactEmailTemplate,
      email,
      webName,
      "Contact Request Email",
      formValues,
      `Contact Request : ${formValues.Subject}`
    );
  };
  const handleFormInput = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => {
      return { ...prev, [name]: value };
    });
  };
  return (
    <div className="Contact-Us">
      <div className="Contact-Info">
        <div className="Info-Box">
          <img src={Data.LocationIcon ? Data.LocationIcon : Location} />
          <div className="addressWrapper">
            <div className="address">
              <h5>{Data.firstAddressText}</h5>
              <p>{Data.firstAddress}</p>
            </div>
            <div className="address">
              <h5>{Data.secondAddressText}</h5>
              <p>{Data.secondAddress}</p>
            </div>
          </div>
        </div>
        <div className="Info-Box">
          <img src={Data.EmailIcon ? Data.EmailIcon : Email} />
          <p>{Data.Email}</p>
        </div>
        <div className="Info-Box">
          <img src={Data.PhoneIcon ? Data.PhoneIcon : Phone} />
          <p>{Data.Phone}</p>
        </div>
      </div>
      <div className="form-map">
        <div className="Map">
          <Map
            locations={[
              {
                lat: +Data.firstAddressLatitude,
                lng: +Data.firstAddressLongitude,
              },
              {
                lat: +Data.secondAddressLatitude,
                lng: +Data.secondAddressLongitude,
              },
            ]}
          />
        </div>

        <div className="Left">
          {Data.formTitle && <h2>{Data.formTitle}</h2>}
          <form className="ContactForm">
            <Input
              label="Name:"
              type="text"
              required={true}
              id="Fname"
              name="Fname"
              value={formValues.Fname}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Email:"
              type="email"
              required={true}
              id="Email"
              name="Email"
              value={formValues.Email}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Contact Number:"
              type="number"
              required={true}
              id="Number"
              name="Number"
              value={formValues.Number}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              label="Subject:"
              type="text"
              required={true}
              id="Subject"
              name="Subject"
              value={formValues.Subject}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />

            <Input
              textarea={true}
              label="Message:"
              type="textarea"
              required={true}
              id="Message"
              name="Message"
              value={formValues.Message}
              customWidth="98%"
              onChangeFunction={handleFormInput}
            />
            <ReCAPTCHA
              sitekey="6LeI5P0iAAAAAEc3s5NTuyTgQIxUhIjIDfwRKt2M"
              onChange={onReCAPTCHAChange}
              ref={recaptchaRef}
              className="CAPTCHA"
              theme="dark"
            />
            <button onClick={sendEmail} className="Button">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Template14;
