/* eslint-disable react/jsx-key */
/* eslint-disable no-debugger */
import React, { useEffect, useState, useRef } from "react";
import "./ArticlePage.css";
import { useParams, Link } from "react-router-dom";
import NotFound from "../../NotFound/NotFound";
import {
  GETCOLLECTION,
  GETDOC,
  SETDOC,
  UPDATEDOC,
  decrypt,
} from "../../../server";
import { CreateToast } from "../../../App";
import date from "date-and-time";
import sortBy from "sort-by";
import { FiEye } from "react-icons/fi";
import { SlCalender } from "react-icons/sl";
import { CiClock1 } from "react-icons/ci";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import Input from "../../Input/Input";
import Loading from "../../Loading/Loading";

const ArticlePage = ({ Users }) => {
  const isFirstRender = useRef(true);
  const pattern = date.compile("ddd, MMM DD YYYY");
  const { ID } = useParams();

  const [Articles, setArticles] = useState([]);
  const [UserLiked, setUserLiked] = useState(false);
  const [TargetArticle, setTargetArticle] = useState(null);
  const [User, setUser] = useState(null);
  const [comment, setComment] = useState({
    replyText: "",
    PersonId: User,
    Date: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      const fetchedArticles = await GETCOLLECTION("Articles");
      const targetArticle = fetchedArticles.find(
        (article) => article.Title === ID
      );

      setArticles(fetchedArticles);

      if (targetArticle) {
        await UPDATEDOC("Articles", ID, {
          ...targetArticle,
          views: targetArticle.views++,
        });
      }

      setLoading(false);
    };

    fetchArticles();
  }, [ID]);

  useEffect(() => {
    setTargetArticle(Articles.find((article) => article.Title === ID));
  }, [Articles, ID]);

  useEffect(() => {
    const fetchUser = async () => {
      const activeUser = JSON.parse(sessionStorage.getItem("activeUser"));
      if (activeUser) {
        const decryptedId = decrypt(activeUser.id);
        const user = await GETDOC("users", decryptedId);
        setUser(user);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (User && TargetArticle) {
      const userLiked = TargetArticle.liked.includes(User.id);
      setUserLiked(userLiked);
    }
  }, [User, TargetArticle]);

  useEffect(() => {
    if (!isFirstRender.current && TargetArticle) {
      SETDOC("Articles", TargetArticle.id, { ...TargetArticle });
    } else {
      isFirstRender.current = false;
    }
  }, [TargetArticle]);

  const handleLike = () => {
    if (!User) {
      CreateToast("You aren't signed in", "error");
      return;
    }

    const userAlreadyLiked = TargetArticle.liked.includes(User.id);
    const updatedLikes = userAlreadyLiked
      ? TargetArticle.liked.filter((userId) => userId !== User.id)
      : [...TargetArticle.liked, User.id];

    setTargetArticle((prev) => ({ ...prev, liked: updatedLikes }));
    CreateToast(userAlreadyLiked ? "Like Removed" : "Article Liked", "success");
  };

  const handleChange = (e) => {
    const now = new Date();
    const { name, value } = e.target;

    setComment((prev) => ({
      ...prev,
      [name]: value,
      Date: date.format(now, pattern),
      PersonId: User ? User.id : prev.PersonId,
    }));
  };

  const handleAddComment = () => {
    if (!User) {
      CreateToast("You aren't signed in", "error");
      return;
    }

    setComment((prev) => ({ ...prev, replyText: "" }));
    setTargetArticle((prev) => ({
      ...prev,
      replies: [comment, ...prev.replies],
    }));
  };

  const renderComments = TargetArticle?.replies.map((reply) => {
    const person = Users.find((user) => user.id === reply.PersonId);
    const nameToRender = person
      ? `${person.Fname} ${person.Lname}`
      : "DELETED USER";

    return (
      <div className="CommentBody" key={reply.Date}>
        <h3>{nameToRender}</h3>
        <span>{reply.Date}</span>
        <p>{reply.replyText}</p>
      </div>
    );
  });

  const articlesSorted = [...Articles].sort(sortBy("id"));
  const firstID = articlesSorted[0]?.id;
  const lastID = articlesSorted[articlesSorted.length - 1]?.id;
  const currentIndex = articlesSorted.findIndex(
    (article) => article.Title === ID
  );
  const nextArticle = articlesSorted[currentIndex + 1]?.Title;
  const previousArticle = articlesSorted[currentIndex - 1]?.Title;

  return (
    <div className="BlogPage BlogMain">
      {loading ? (
        <div className="overlay BlogPage" style={{ zIndex: 4 }}>
          <Loading loading={loading} />
        </div>
      ) : TargetArticle ? (
        <>
          <div className="ArticlePage">
            <h1>{TargetArticle.Title}</h1>
            <div className="Thumbnail PhotoEffect">
              <img src={TargetArticle.thumbnail} alt={TargetArticle.Title} />
            </div>
            <div className="ArticleDetails">
              <span>
                <SlCalender className="icon" />
                {TargetArticle.DateAdded}
              </span>
              <span>
                <FiEye className="icon" />
                {TargetArticle.views}
              </span>
              <span className="LikeButton">
                {UserLiked ? (
                  <AiFillLike className="icon" onClick={handleLike} />
                ) : (
                  <AiOutlineLike className="icon" onClick={handleLike} />
                )}
                {TargetArticle.liked.length}
              </span>
              <span>
                <CiClock1 className="icon" /> {TargetArticle.ReadTime} min(s)
              </span>
              <div>
                <BiCategory className="icon" />
                {TargetArticle.category}
              </div>
            </div>
            <div
              className="ArticleBody"
              dangerouslySetInnerHTML={{ __html: TargetArticle.PostBody }}
            ></div>
            <div className="Comments">
              <div className="AddComment">
                {User ? (
                  <>
                    <Input
                      textarea={true}
                      label="Share Your Thoughts"
                      required={true}
                      id="replyText"
                      name="replyText"
                      value={comment.replyText}
                      onChangeFunction={handleChange}
                      customClass="TextArea"
                    />
                    <button className="Button" onClick={handleAddComment}>
                      Post Comment
                    </button>
                  </>
                ) : (
                  <p>Login to be able to comment</p>
                )}
              </div>
              {renderComments}
            </div>
            <div className="Navigation">
              {firstID !== ID && (
                <Link
                  to={previousArticle && `/Blog/${previousArticle}`}
                  className="Link Reverse"
                >
                  Read Previous
                </Link>
              )}
              <Link
                style={{ textDecoration: "none" }}
                to="/Blog"
                className="Home"
              >
                Blog
              </Link>
              {lastID !== ID && (
                <Link
                  to={nextArticle && `/Blog/${nextArticle}`}
                  className="Link"
                >
                  Read Next
                </Link>
              )}
            </div>
          </div>
        </>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default ArticlePage;
